/**
 * Chartist.js plugin that enhances the diagrams with a title and a description for better
 * accessibility. It's also adds role='img' and an aria-labelledby-attribute.
 * Aria-describedby should be implemented, if user agent support improved.
 */
/* global Chartist */
(function (window, document, Chartist) {
  'use strict';
  var defaultOptions = {
    title: 'Ein Diagramm',
    description: 'Die Daten zu diesem Diagramm finden Sie in der nachfolgenen Tabelle.',
    elementId: function () {
      return 'acc' + +new Date()
    }
  };
  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.ctAccessibility = function (options) {
    options = Chartist.extend({}, defaultOptions, options);
    return function ctAccessibility(chart) {
      var elementId = typeof options.elementId === 'function' ? options.elementId() : options.elementId;
      chart.on('created', function (data) {
        if (data.options.classNames.chart == 'ct-chart-bar') {
          options.title = 'Ein Balken-Diagramm'
        } else if (data.options.classNames.chartPie == 'ct-chart-pie') {
          options.title = 'Ein Torten-Diagramm'
        } else if (data.options.classNames.chart == 'ct-chart-line') {
          options.title = 'Ein Linien-Diagramm'
        } else {
          console.log('Unknown Type: ', data.options.classNames)
        }
        var chartElement = data.svg._node.parentNode;
        var containerElement = chartElement.parentNode;
        var previousElement = containerElement.querySelector('#' + elementId);
        if (previousElement) {
          containerElement.removeChild(previousElement)
        }
        // according to Léonie Watson (http://www.sitepoint.com/tips-accessible-svg/)
        // we add some accessibility to the svg itself
        data.svg.attr({
          'role': 'img',
          'aria-labelledby': elementId + 'Title ' + elementId + 'Description'
        });
        // should be replaced by this code, if user agent support improved
        //        data.svg.attr({
        //          'role': 'img',
        //          'aria-labelledby': elementId + 'Title ',
        //          'aria-describedby': elementId + 'Description ct-accessibility-table-' + elementId.replace('acc', '')
        //        });
        var xmlns = 'http://www.w3.org/2000/svg';
        var titleElement = document.createElementNS(xmlns, 'title');
        titleElement.setAttributeNS(null, 'id', elementId + 'Title');
        titleElement.appendChild(document.createTextNode(options.title));
        data.svg._node.appendChild(titleElement);
        var descriptionElement = document.createElementNS(xmlns, 'desc');
        descriptionElement.text = options.description;
        descriptionElement.setAttributeNS(null, 'id', elementId + 'Description');
        descriptionElement.appendChild(document.createTextNode(options.description));
        data.svg._node.appendChild(descriptionElement)
      })
    }
  }
}(window, document, Chartist))